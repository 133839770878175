import { createTheme } from '@mui/material';
import "@fontsource/lato"; // Defaults to weight 400
import "@fontsource/lato/400.css"; // Specify weight
import "@fontsource/lato/400-italic.css"; // Specify weight and style

export const theme = createTheme({
    typography: {
        fontFamily: ['Lato'],
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                }
            }
        }
    },
    mixins: {
        MuiDataGrid: {
            containerBackground: '#8E9AAF',
            containerColor: '#fff',
        },
    },
    palette: {
        
        primary: {
            main: '#312783',
            dark: '#2C2E43'
        },
        secondary: {
            main: '#BE1717'
        },
        accent: {
            main: '#312783',
            dark: '#8E9AAF'
        },
        action: {
            active: '#312783',
            focus: '#312783',
            selected: '#312783',
            hover: '#8E9AAF'
        },
        // categories: {
        //     administration: {
        //         main: '#1eaf3e',
        //         light: '#24d54b'
        //     },
        //     'operations-planning': {
        //         main: '#812ba4',
        //         light: '#9f35ca'
        //     },
        //     reports: {
        //         main: '#ff3737',
        //         light: '#ff4437'
        //     }
        // },
        // categoryColors: ['#1d4e71', '#0f2a3d']
    }
});
